import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { MICROSERVICE_CHILD, MICROSERVICE_WISHLIST } from '../../../../userglobals'
import styles from '../Products.module.scss'
import classNames from 'classnames/bind'
import axios from 'axios'
import {
  addToWishlist,
  removeFromWishlist
} from '../../../store/actions'
import {
  ButtonAddToCartConfigurable,
  ButtonAddToWishlist,
  ButtonShare,
  IncludedItens,
  CustomOptions,
  ProductDetails,
  ProductImagesCarousel,
  ProductName,
  ProductPrice,
  ProductReviews,
  ProductSpecifications,
  ProductSwatches,
  Sizes,
  Tips
} from '../components'
import {
  ProductType,
  ProductAttributeType,
  ProductFilterType,
  media_gallery_entry
} from 'types'


type ChildProductType = {
  child: ProductType;
  productAttributes: ProductAttributeType[];
  filter: ProductFilterType[];
  location: Location
}

const handleCustomOptionFromLocation = (location: any) => {
  //Se caso o state de location não for nulo e existir customOptions dentro dele, ele retorna o que há dentro do state de location
  if (location && location !== null !== undefined && location.state !== null && location.state !== undefined && 'customOptions' in location.state) {
    return location.state.customOptions
  }
  //Caso contrário, ele seta um state inicial customOptions com base nas custom options do produto inicial
  //Se caso o produto não tiver customOptions será simplesmente retornado um array vazio
  else {
    return []
  }
}

const ChildProduct: React.FC<ChildProductType> = ({ child, productAttributes, filter, location }) => {
  const [customOptions, setCustomOptions] = useState(handleCustomOptionFromLocation(location));
  const [checked, setChecked] = useState(false);
  const wishlist = useSelector((state: any) => state.wishlist);
  const userAuth = useSelector((state: any) => state.userAuth);
  // console.log(child)
  // console.log(filter)
  const handleChangeProduct = (newFilter: any) => {
    axios.post(MICROSERVICE_CHILD + 'getBrother', { fatherSKU: child.product_links[0].sku, filter: newFilter })
      .then(res => {
        //console.log(res.data.slug)
        navigate("/" + res.data.slug, { state: { customOptions } })
      })
  }
  useEffect(() => {
    const alreadyChecked = wishlist?.some((x: any) => x?.product_id === child.id.toString());
    const alreadyChecked2 = wishlist?.some((x: any) => x=== child.id);
    if (alreadyChecked || alreadyChecked2) {
      setChecked(true)
    }
  }, [handleChangeProduct])

  const toggleCheckbox = () => {
    if (!checked) {
      setChecked(true)
      addToWishlist(child.id)
      console.log(child)
      if (userAuth.auth === true) {
        axios.post(MICROSERVICE_WISHLIST + "add-wishlist", { ...userAuth, productId: child.id })
        // .then(res=>console.log(res.data))
      }
    } else {
      setChecked(false)
      removeFromWishlist(child.id)
      if (userAuth.auth === true) {
        axios.post(MICROSERVICE_WISHLIST + "delete-wishlist", { ...userAuth, productId: child.id })
        // .then(res=>console.log(res.data))
      }
    }
  };

  const handleCustomOptionChange = (label: any, value: any, id: number) => {
    // //console.log(label)

    const newCustomOptions = [...customOptions];
    //const indexID = customOptionsFile.findIndex(x=>x.title===label);
    const indexCustom = newCustomOptions.findIndex(x => x.label === label);
    if (indexCustom === -1) {
      newCustomOptions.push({ label: label, value: value, id: id });
    } else {
      newCustomOptions[indexCustom].value = value;
    }
    setCustomOptions(newCustomOptions)
  }
  const productImagesUrls = child.media_gallery_entries.map((item: media_gallery_entry) => item.file);
  //console.log(child)
  return (
    <div className={classNames(styles.container)}>
      <ProductImagesCarousel productImagesUrls={productImagesUrls} />
      <ProductName productName={child.name} sku={child.sku} product={child} checked={checked} toggleCheckbox={toggleCheckbox} />
      <ProductPrice child={child} />
      {
        child.product_template === '1' ?
          <ProductSwatches swatches={child.product_links[0].extension_attributes.configurable_product_options} filter={filter} handleChangeProduct={handleChangeProduct} /> :
          child.product_template === '2' || child.product_template === '3' || child.product_template === '7' || child.product_template === '8' ?
            <>
              <Sizes />
              <CustomOptions template={child.product_template} customOptions={child.product_links[0].options} handleCustomOptionChange={handleCustomOptionChange} customOptionsState={customOptions} />
              <ProductSwatches swatches={child.product_links[0].extension_attributes.configurable_product_options} filter={filter} handleChangeProduct={handleChangeProduct} />
            </> :
            <>
              <CustomOptions template={child.product_template} customOptions={child.product_links[0].options} handleCustomOptionChange={handleCustomOptionChange} customOptionsState={customOptions} />
              <ProductSwatches swatches={child.product_links[0].extension_attributes.configurable_product_options} filter={filter} handleChangeProduct={handleChangeProduct} />
            </>
      }
      <ButtonAddToCartConfigurable child={child} filter={filter} customOptionsState={customOptions} />
      <div className={styles.gridWishlistShareSizes}>
        <ButtonAddToWishlist product={child} checked={checked} toggleCheckbox={toggleCheckbox} />
        <ButtonShare />
      </div>
      <IncludedItens customAttributes={child.custom_attributes} />
      <ProductDetails customAttributes={child.custom_attributes} />
      <ProductSpecifications customAttributes={child.custom_attributes} disponibilidade={child.custom_attributes.findIndex(x => x.defaultFrontendLabel === 'Disponibilidade')} />
      <Tips customAttributes={child.custom_attributes} />
      <ProductReviews />
    </div>
  )

}

export default ChildProduct
import React from "react"
import Layout from "../components/Layout/Layout"
import ChildProduct from "../components/Products/ChildProduct/ChildProduct"
import { MAGENTO_URL } from '../../userglobals'
import {
  ProductType,
  ProductAttributeType,
  ProductFilterType,
} from "../../types"
import SEO from "../components/seo"
import { graphql } from "gatsby"

type ChildProductTemplateType = {
  pageContext: {
    child: ProductType
    productAttributes: ProductAttributeType[]
    filter: ProductFilterType[]
    lastmoddate: String
  }
  location: Location
  data: any
}

const childProductTemplate: React.FC<ChildProductTemplateType> = ({
  pageContext: { child, productAttributes, filter, lastmoddate },
  location,
  data
}) => {
  var metaTitle = ""
  var metaDescription = ""
  var metaKeyword = ""
  const { site } = data;
  const schema = {
   "@context": "https://schema.org/", 
   "@type": "Product", 
   "name": child.name,
   "image": MAGENTO_URL + "media/catalog/product" + child.image,
   "description": child.custom_attributes.find(x => x.attribute_code === 'description').value,
   "brand": "Virtual Joias",
   "sku": child.sku,
   "offers": {
     "@type": "Offer",
     "url": site.siteMetadata.siteUrl + "/" + child.slug,
     "priceCurrency": "BRL",
     "price": child.price
   }
 }
  child.custom_attributes.forEach(x => {
    switch (x.attribute_code) {
      case "meta_title": {
        metaTitle = x.value
        break
      }
      case "meta_description": {
        metaDescription = x.value
        break
      }
      case "meta_keyword": {
        metaKeyword = x.value
        break
      }
      default: {
        break
      }
    }
  })
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeyword}
        schemaMarkup={schema}
      />
      <ChildProduct
        child={child}
        productAttributes={productAttributes}
        filter={filter}
        location={location}
      />
    </Layout>
  )
}

export default childProductTemplate

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
